import Vue from "vue";
import store from "./store";
// import {isMobile} from "mobile-device-detect";
import Router from "vue-router";
import NProgress from "nprogress";
// import checkStatus from "./auth/checkStatus";
// import checkInfo from "./views/app/withdraw/checkInfo";
// import checkRequestDeposit from "./auth/checkRequestDeposit";
import checkSecPassword from "./auth/checkSecPassword";
// import checkInfo from "./views/app/withdraw/checkInfo";
// import authenticate from "./auth/authenticate";
Vue.use(Router);

// create new router

const routes = [
  {
    path: "/web/",
    component: () => import("./views/app"), //webpackChunkName app
    beforeEnter: checkSecPassword,
    redirect: "/web/wallet",

    children: [
      
      {
        path: "/web/test",
        component: () => import("./views/app/transfer/test"),
      },

      {
        path: "/web/wallet",
        component: () => import("./views/app/transfer/wallet"),
      },
      {
        path: "/web/upgradePackage",
        component: () => import("./views/app/package/upgradePackage"),
      },
      {
        path: "/web/member",
        component: () => import("./views/app/team/member"),
      },

      {
        path: "/web/bonus/bonusRecord",
        component: () => import("./views/app/bonus/bonusRecord"),
      },

      {
        path: "/web/bonus/share2EarnRecord",
        props: true,
        component: () => import("./views/app/bonus/share2earnbonus"),
      },

      {
        path: "/web/bonus/newbonusRecord",
        name: "newbonusRecord",
        component: () => import("./views/app/bonus/newbonusRecord"),
      },
      
      {
        path: "/web/me/mePage",
        component: () => import("./views/app/me/profile"),
      },
      
      {
        path: "/web/me/invitation_link",
        component: () => import("./views/app/me/invitation_link"),
      },
      
      {
        path: "/web/me/news",
        component: () => import("./views/app/me/newsList"),
      },
      
      {
        path: "/web/me/basic_info",
        name: "basic_info",
        props: true,
        component: () => import("./views/app/me/basic_info"),
      },
      
      {
        path: "/web/me/newsDetails",
        name: "newDetails",
        props: true,
        component: () => import("./views/app/me/newsDetails"),
      },
      
      {
        path: "/web/me/KYC",
        component: () => import("./views/app/me/KYC"),
      },
      {
        path: "/web/me/password",
        component: () => import("./views/app/me/passwordSetting"),
      },

      {
        path: "/web/earnings",
        component: () => import("./views/app/earnings/earnings"),
      },

      {
        path: "/web/arbitrage",
        component: () => import("./views/app/arbitrage/arbitrage"),
      },

      {
        path: "/web/tree",
        component: () => import("./views/app/network/tree"),
      },

      {
        path: "/web/arbitrage/packageHistory",
        component: () => import("./views/app/arbitrage/packageHistory"),
      },

      {
        path: "/web/mywallet",
        component: () => import("./views/app/mywallet/mywallet"),
      },
      {
        path: "/web/package/packageHistory",
        component: () => import("./views/app/package/packageHistory"),
      },

      {
        path: "/web/sell",
        component: () => import("./views/app/sell/sell"),
      },

      {
        path: "/web/convert",
        component: () => import("./views/app/convert/convert"),
      },
      {
        path: "/web/redeem",
        component: () => import("./views/app/withdraw/redeem"),
      },
      {
        path: "/web/redeemAble",
        component: () => import("./views/app/withdraw/redeemAble"),
      },

      {
        path: "/web/sell/sellRecord",
        component: () => import("./views/app/sell/sellRecord"),
      },

    ]
  },
  // {
  //   path: "/web/package",
  //   beforeEnter: checkSecPassword,
  //   component: () => import("./views/app/package"),
  //   redirect: "/web/package/packageHistory",
  //   children: [
  //     {

  //       path: "packageHistory",
  //       component: () => import("./views/app/package/packageHistory"),
  //     },
  //   ]
  // },
  {
    path: "/web/wallet/record",
    beforeEnter: checkSecPassword,
    component: () => import("./views/app/transfer/walletRecord"),
  },
  {
    path: "/web/wallet/recordDetails",
    name: "recordDetails",
    props: true,
    beforeEnter: checkSecPassword,
    component: () => import("./views/app/transfer/recordDetails"),
  },
  {
    path: "/web/transfer",
    beforeEnter: checkSecPassword,
    component: () => import("./views/app/transfer"),
    redirect: "/web/transfer/transfer",
    children: [
      {
        path: "transfer",
        component: () => import("./views/app/transfer/transfer"),
      },
      {
        path: "transferRecord",
        component: () => import("./views/app/transfer/transferRecord"),
      },
      {
        path: "changeWallet",
        component: () => import("./views/app/transfer/changeWallet"),
      },
    ]
  },
  {
    path: "/web/bonus",
    beforeEnter: checkSecPassword,
    component: () => import("./views/app/bonus"),
    redirect: "/web/bonus/bonusCenter",
    children: [
      {
        path: "bonusCenter",
        component: () => import("./views/app/bonus/bonusCenter"),
      },
      {
        path: "/web/bonus/bonusRecord",
        beforeEnter: checkSecPassword,
        component: () => import("./views/app/bonus/bonusRecord"),
      },
      
    ]
  },
  {
    path: "/web/withdraw",
    beforeEnter: checkSecPassword,
    component: () => import("./views/app/withdraw"),
    redirect: "/web/withdraw/withdraw",
    children: [
      {
        path: "withdraw",
        component: () => import("./views/app/withdraw/withdraw"),
      },
      {
        path: "withdrawHistory",
        component: () => import("./views/app/withdraw/withdrawHistory"),
      },
    ]
  },
  {
    path: "/web/deposit",
    beforeEnter: checkSecPassword,
    // component: () => import("./views/app/deposit"),
    // redirect: "/web/deposit/deposit",
    component: () => import("./views/app"),
    redirect: "/web/deposit",
    children: [
      {
        path: "deposit",
        component: () => import("./views/app/deposit/deposit"),
      },
      {

        path: "depositHistory",
        component: () => import("./views/app/deposit/depositHistory"),
      },
    ]
  },
 
  {
    path: "/web/settings/forget-password",
    component: () => import("./views/app/settings/forget-password"),
  },
  {
    path: "/web/settings/set-sec-password",
    component: () => import("./views/app/settings/set-sec-password"),
  },
  {
    path: "/web/settings/personal_info",
    component: () => import("./views/app/settings/personal_info"),
  },
  {
    path: "/web/settings/checkSecPass",
    component: () => import("./views/app/settings/checkSecPass"),
  },

  // sessions
  {
    path: "/web/sessions",
    component: () => import("./views/app/sessions"),
    redirect: "/get_started",
    children: [
      {
        path: "/get_started",
        component: () => import("./views/app/sessions/get_started"),
      },
      {
        path: "signIn",
        component: () => import("./views/app/sessions/signIn")
      },
      // {
      //   path: "signUp",
      //   component: () => import("./views/app/sessions/signUp")
      // },
      // {
      //   path: "forgot",
      //   component: () => import("./views/app/sessions/forgot")
      // }
    ]
  },

  {
    path: "/register",
    component: () => import("./views/app/sessions/signUp"),
  },
  
  

  {
    path: "/newpinsuccess",
    component: () => import("./views/app/popup/newpinsuccess"),
  },

  {
    path: "/newacc_success",
    component: () => import("./views/app/pages/newacc_success"),
  },

  // {
  //   path: "/vertical-sidebar",
  //   component: () => import("./containers/layouts/verticalSidebar")
  // },
  {
    path: "*",
    component: () => import("./views/app/pages/notFound")
  },

  {
    path: "/web/logout",
    component: () => import("./views/app/popup/profilelogout"),
  },
   
  {
    path: "/web/share2earn",
    component: () => import("./views/app/popup/share2earn"),
  },

  {
    path: "/web/profilesuccess",
    component: () => import("./views/app/popup/profilesuccess"),
  },

  {
    path: "/web/keyinamount",
    component: () => import("./views/app/popup/keyinamount"),
  },

  {
    path: "/web/enter_pin",
    component: () => import("./views/app/pin/enter_pin"),
  },
];

const router = new Router({
  mode: "history",
  linkActiveClass: "open",
  routes,
  // to, from, savedPosition
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
});

router.beforeEach((to, from, next) => {
  // If this isn't an initial page load.
  if (to.path) {
    // Start the route progress bar.

    NProgress.start();
    NProgress.set(0.1);
  }
  // if (localStorage.getItem('path') != null) {
  //   var permissionList = localStorage.getItem('path');
  //   console.log((permissionList.includes(to.meta.permission)))
  //   try {
  //     // if (to.path == '/') {
  //     //   next('/');
  //     // }

  //     if (to.meta.permission != undefined && to.path != '/*') {
  //       if(permissionList.includes(to.meta.permission)){
  //         next();

  //       }else{
  //         next('*');
  //       }
  //     } else {
  //       next();
  //     }

  //   } catch (error) {
  //     console.log(error)
  //   }

  // } else {
  //   localStorage.removeItem("etnexToken");

  // }
  next();
});

router.afterEach(() => {
  // Remove initial loading
  const gullPreLoading = document.getElementById("loading_wrap");
  if (gullPreLoading) {
    gullPreLoading.style.display = "none";
  }
  // Complete the animation of the route progress bar.
  setTimeout(() => NProgress.done(), 100);
  // NProgress.done();
  // if (isMobile) {
  if (window.innerWidth <= 1200) {
    // console.log("mobile");
    store.dispatch("changeSidebarProperties");
    if (store.getters.getSideBarToggleProperties.isSecondarySideNavOpen) {
      store.dispatch("changeSecondarySidebarProperties");
    }
  } else {
    if (store.getters.getSideBarToggleProperties.isSecondarySideNavOpen) {
      store.dispatch("changeSecondarySidebarProperties");
    }

    // store.state.sidebarToggleProperties.isSecondarySideNavOpen = false;
  }
});

export default router;
