import { defineStore } from "pinia";
import { ethers } from "ethers";
// import { getAbi, getERC20_Abi, getUSDTABI, piAbi } from './abi'
// import keccak256 from "keccak256";
// import { MerkleTree } from "merkletreejs";

export const useMetatool = defineStore("metatool", () => {

    // const factoryContractAddress = '0x1aA8f4dDBD857390C6961073a09722e8A6C1Ef64';


    var errors = {};
    var isInit = false;
    var metamaskEnabled = false;
    var currentAccount = '';
    var currentChainId = '';
    var chainIds = [56];
    var chainIdsData = {
        56: {
            chainId: '0x38',
            chainName: 'BNB Smart Chain Mainnet',
            chainNameShort: 'Binance',
            type: 'M',
            nativeCurrency: {
                name: 'BNB',
                symbol: 'BNB',
                decimals: 18
            },
            rpcUrls: ['https://bsc.publicnode.com/'],
            blockExplorerUrls: ['https://bscscan.com']
        },
        97: {
            chainId: '0x61',
            chainName: 'BNB Smart Chain Testnet',
            chainNameShort: 'Binance',
            type: 'T',
            nativeCurrency: {
                name: 'BNB',
                symbol: 'BNB',
                decimals: 18
            },
            rpcUrls: ['https://endpoints.omniatech.io/v1/bsc/testnet/public'],
            blockExplorerUrls: ['https://bscscan.com']
        },

    }
    async function getCurrentAccountFromMetamask() {
        return await window.ethereum.request({ method: 'eth_accounts' });
    }
    async function getCurrentChainIdFromMetamask() {
        const provider = new ethers.providers.Web3Provider(window.ethereum)
        const signer = provider.getSigner();
        const chainId = await signer.getChainId();
        return chainId
    }
    async function getCurrentChainNetworkFromMetamask() {
        const provider = new ethers.providers.Web3Provider(window.ethereum)
        const signer = provider.getSigner();
        const chainId = await signer.getChainId();
        let chainNetwork;
        for (const [key, value] of Object.entries(chainIdsData)) {
            if (key == chainId) {
                chainNetwork = JSON.stringify(value);
            }
        }
        return chainNetwork
    }
    async function requestAccountFromMetamask() {
        try {
            return await window.ethereum.request({ method: 'eth_requestAccounts' });
        } catch (error) {
            errors = {
                requestAccount: error
            }
            return ''
        }
    }
    function checkIsMetamaskEnabled() {
        return typeof window.ethereum !== 'undefined'
    }
    async function connectWallet() {
        if (checkIsMetamaskEnabled()) {
            metamaskEnabled = true;
            let rs = await getCurrentAccountFromMetamask()

            if (rs.length == 0) {
                rs = await requestAccountFromMetamask()
            }
            // console.log('rs', rs);
            currentAccount = rs[0] || '';

            currentChainId = await getCurrentChainIdFromMetamask();

            if (!chainIds.includes(currentChainId)) {
                await switchChainId(56);
                // await switchChainId(97);

            }


            return currentAccount;
        } else {
            if (window.outerWidth < 480) {
                alert("Please install Metamask and use it's internal browser");

            } else {
                window.open('https://metamask.io/download/', '__blank');

            }
        }
    }
    async function switchChainId(id) {
        try {
            var tmp = {}
            tmp['chainId'] = chainIdsData[id].chainId;
            tmp['chainName'] = chainIdsData[id].chainName;
            tmp['rpcUrls'] = chainIdsData[id].rpcUrls;
            tmp['nativeCurrency'] = chainIdsData[id].nativeCurrency;
            await window.ethereum.request({
                method: 'wallet_addEthereumChain',
                params: [
                    tmp
                ],
            });
            return true
        } catch (error2) {
            console.log(error2)
            errors = {
                addEthereumChain: error2
            }
            return false
        }
    }

    async function getBalanceFromMetamask() {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        let balance = await provider.getBalance(currentAccount)
        balance = ethers.utils.formatEther(balance)
        // console.log(`balance: ${balance} ETH`);
        return balance;
    }

    metamaskEnabled = checkIsMetamaskEnabled();
    if (metamaskEnabled) {
        getCurrentAccountFromMetamask().then(acc => {
            currentAccount = acc[0]
        });
        getCurrentChainIdFromMetamask().then(chainId => {
            currentChainId = chainId
        })

        window.ethereum.on('accountsChanged', async (accounts) => {
            if (accounts.length === 0) {
                currentAccount = ''
                console.log('Please connect to MetaMask.');
                localStorage.removeItem('etnexToken');
                window.location.reload();
            } else if (accounts[0] !== currentAccount) {
                if (currentAccount) {
                    localStorage.removeItem('etnexToken');
                    window.location.reload();

                }
                currentAccount = accounts[0]
                console.log('accountsChanged', accounts);
            }
        });

        window.ethereum.on('chainChanged', (chainId) => {
            currentChainId = ethers.BigNumber.from(chainId).toNumber()
            console.log('chainChanged', ethers.BigNumber.from(chainId).toNumber(), chainId);
            localStorage.removeItem('etnexToken');
            window.location.reload();
        });
        isInit = true;
    } else {
        currentAccount = ''
        console.log('Please install MetaMask.');
    }

    return {
        errors,
        metamaskEnabled,
        currentAccount,
        currentChainId,
        chainIds,
        // chainIdsTestNet, chainIdsMainnet,
        chainIdsData,
        isInit,
        getBalanceFromMetamask,
        getCurrentAccountFromMetamask,
        checkIsMetamaskEnabled,
        connectWallet,
        switchChainId,
        getCurrentChainNetworkFromMetamask,
        getCurrentChainIdFromMetamask,
    }
})