import {
    getMemberInfo, getDownloadLink
} from "../system/api/api";
import { useMetatool } from '../system/crypto/config'

export default async (to, from, next) => {
    const metaTool = useMetatool();

    try {
        const currentAddress = await metaTool.connectWallet();
      
        if(currentAddress!=localStorage.getItem("username")){
            localStorage.removeItem("etnexToken");
            next("/get_started");
        }
        if (
            localStorage.getItem("etnexToken") != null &&
            localStorage.getItem("etnexToken").length > 0
        ) {
            const result = await getDownloadLink();

            const siteOn = result.data.data.system.SITE_ON;
            localStorage.setItem('SUPPORT_URL_EN', result.data.data.system.SUPPORT_URL_EN);
            localStorage.setItem('SUPPORT_QR_CN', result.data.data.system.SUPPORT_QR_CN);
    
            if (siteOn == 0) {
                next("/web/sessions/signIn");
            } else {
                const result = await getMemberInfo();
                const secPassword = result.data.data.password2;
                const jy = result.data.data.jy;
                const role_id = result.data.data.role_id;

                if (role_id != 2) {
                    next("/web/sessions/signIn");
                }
                if (secPassword == null) {
                    next("/web/settings/personal_info");
                } else {
                    if (jy == 0) {
                        next("/web/sessions/signIn");
                    } else {
                        if(localStorage.getItem("checkSecPass") == 1){
                            console.log('====');
                            console.log(localStorage.getItem("checkSecPass"));
                            console.log('====');
                            next();
                        }else{
                            next("/web/settings/checkSecPass");
                        }
                    }
                }
            }
        } else {
            localStorage.removeItem("etnexToken");
            next("/get_started");
        }
    } catch (error) {
        console.error(error);
        next(); 
    }
};
